import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress';

import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

import BuildingCard from '../components/BuildingCard'

class BuildingCardList extends Component {
    static propTypes = {
        data: PropTypes.object,
    }

    async componentDidMount() {
        this.BuildingQuerySubscription = this.props.data.subscribeToMore({
            document: gql`
                subscription {
                    buildingMutated {
                        id
                        projectID
                        displayName
                        description
                        controlStatus
                        controlStatusSetpoint
                        controlEventHistory {
                            id
                            payload
                        }
                        comfortSettingThermal
                        comfortSettingAirquality
                        lastDataPushDateTime
                        location {
                            id
                            city
                        }
                        image {
                            id
                            file {
                                id
                                url
                            }
                        }
                    }
                }
            `,
            onError: (err) => console.error(err),
        })
    }

    render() {
        return (
            <Grid container spacing={2}>
                {this.props.data.error ? (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography gutterBottom>Error fetching building data!</Typography>
                    </Grid>
                ) : this.props.data.loading ? (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <CircularProgress size={50} color="secondary" />
                    </Grid>
                ) : !this.props.data.me ? (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography gutterBottom>Loading building data...</Typography>
                    </Grid>
                ) : (
                    JSON.parse(JSON.stringify(this.props.data.me.client.buildings))
                        .sort((a, b) => {
                            if (a.activationDateSteering === null && b.activationDateSteering === null) {
                                return a.displayName.localeCompare(b.displayName);
                            }
                            if (a.activationDateSteering !== null && b.activationDateSteering !== null) {
                                return a.displayName.localeCompare(b.displayName);
                            }
                            if (a.activationDateSteering === null) {
                                return 1;
                            }
                            return -1
                        })
                        .map((building, index) => {
                            return (
                                <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={building.id}>
                                    <BuildingCard user={this.props.data.me} building={building} />
                                </Grid>
                            )
                        })
                )}

                {this.props.data.me && this.props.data.me.externalBuildingRoles ? (
                    JSON.parse(JSON.stringify(this.props.data.me.externalBuildingRoles))
                        .sort((a, b) => a.building.displayName.localeCompare(b.building.displayName))
                        .map((b, index) => {
                            return (
                                <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={b.building.id}>
                                    <BuildingCard user={this.props.data.me} building={b.building} />
                                </Grid>
                            )
                        })
                ) : null}
            </Grid>
        )
    }
}

const getCurrentUser = gql`
query getCurrentUser {
    me {
        id
        email
        name
        role
        externalBuildingRoles {
            building {
                id
                projectID
                displayName
                description
                controlStatus
                controlStatusSetpoint
                controlEventHistory {
                    id
                    payload
                }
                comfortSettingThermal
                comfortSettingAirquality
                lastDataPushDateTime
                location {
                    id
                    city
                }
                image {
                    id
                    file {
                        id
                        url
                    }
                }
            }
        }
        client {
            id
            displayName
            logo {
                id
                file {
                    id
                    url
                  }
            }
            buildings(where:{offboardingDate:null}) {
                id
                projectID
                displayName
                description
                activationDateSteering
                controlStatus
                controlStatusSetpoint
                controlEventHistory {
                    id
                    payload
                }
                comfortSettingThermal
                comfortSettingAirquality
                lastDataPushDateTime
                location {
                    id
                    city
                }
                image {
                    id
                    file {
                        id
                        url
                      }
                }
            }
            users {
                id
                name
                email
                role
            }
        }
    }
}`

export default graphql(getCurrentUser)(BuildingCardList);